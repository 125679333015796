import * as React from 'react';
// @ts-ignore
import { HeroSearch } from 'career-page-components';
import './JobSearchForm.css';

interface JobSearchFormProps {
	headline?: string;
	data: any;
	h2?: string;
	p?: string;
	imgSrc?: string;
	alignLeft?: boolean;
	flexCol?: boolean;
}

const JobSearchForm: React.FC<JobSearchFormProps> = (
	props: JobSearchFormProps
) => {
	return (
		<section
			className="flex bg-cover bg-top pt-6 md:h-[800px]"
			style={{ backgroundImage: `url('${props.imgSrc}')` }}
		>
			<div
				className={
					'container mx-auto hero-form flex flex-col items-end justify-end gap-7 self-end px-4' +
					(props.alignLeft ? ' lg:items-start' : ' lg:items-end')
				}
			>
				{props.headline && (
					<div className="w-full md:w-[500px]">
						<p className="text-center text-5xl font-normal leading-tight text-white md:text-left md:text-[40px] lg:text-[50px]">
							{props.headline}
						</p>
					</div>
				)}
				<div
					className={
						(props.flexCol
							? 'section-job-search-form-col md:w-[500px] '
							: 'md:w-[700px] ') +
						'section-job-search-form flex h-2/4 w-full flex-col bg-tertiary px-6 py-4 lg:px-12'
					}
				>
					<h2
						className={`pb-6 pt-5 text-center text-3xl font-normal text-white md:pb-6 md:text-left md:text-5xl`}
					>
						{props.h2 ? props.h2 : 'Search Openings'}
					</h2>
					{props.p && (
						<p className="pb-5 text-center text-3xl font-normal text-white md:text-left">
							{props.p}
						</p>
					)}
					<div>
						<HeroSearch
							data={props.data}
							options={{
								submitButtonText: 'Apply Now',
								searchPath: '/jobs/'
							}}
							apiKey={'AIzaSyAdZiU_HnkEoqTNEIctfBGTagt0qbCyf_0'}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default JobSearchForm;
