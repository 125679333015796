import React from 'react';
import { PageProps, graphql, useStaticQuery } from 'gatsby';

import Layout from '../layouts/Layout';
import JobSearchForm from '../components/JobSearchForm/JobSearchForm';
import TextSection from '../components/TextSection/TextSection';
import FeaturedCareers from '../components/FeaturedCareers/FeaturedCareers';
import Values from '../components/Values/Values';
import Benefits from '../components/Benefits/Benefits';
import OurMission from '../components/OurMission/OurMission';
import ReadyStartSection from '../components/ReadyStartSection/ReadyStartSection';

import menuItems from '../constants/menuItems';
import heroImg from '../images/landing-hero.png';

const IndexPage = (props: PageProps) => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	return (
		<Layout
			location={props.location}
			menuItems={menuItems}
			title={'Coach USA Careers'}
			desc={'Coach USA Careers'}
		>
			<JobSearchForm
				data={data}
				alignLeft
				imgSrc={heroImg}
				h2="Put your career on a dependable route!"
				p="Join our team and discover great new job opportunities!"
				flexCol
			></JobSearchForm>
			<TextSection
				h2={'About Us'}
				p={
					'We are one of the largest transportation companies in North America operating more than 2,400 motorcoaches and employing more than 5,000 people. Sister companies Coach USA and Megabus provide local and inter-city transportation services for communities throughout the US and Canada. Coach USA owns more than 25 bus carriers in North America, operating scheduled bus routes, airport transportation, motorcoach tours, and charters. These local carriers are independently managed and operated. Some of these carriers have been in existence for more than 75 years and are a vital part of the community.'
				}
			/>
			<FeaturedCareers />
			<Values />
			<Benefits />
			<OurMission />
			<ReadyStartSection />
		</Layout>
	);
};
 
export default IndexPage;
