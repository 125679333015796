import * as React from 'react';

import training from '../../images/training-icon.svg';
import salaries from '../../images/salaries-icon.svg';
import bonus from '../../images/bonus-icon.png';
import insurance from '../../images/insurance-icon.svg';

const Benefits: React.FC = () => {
	const items = [
		{
			icon: training,
			title: 'Paid Certified Training Programs',
			bgColorClass: 'bg-primary'
		},
		{
			icon: salaries,
			title: 'Competitive Salaries',
			bgColorClass: 'bg-quaternary'
		},
		{
			icon: bonus,
			title: 'Sign-On Bonus',
			text: '(Dependent on Location)',
			bgColorClass: 'bg-quinary'
		},
		{
			icon: insurance,
			title: 'Medical, Dental, Vision, & Life Insurance',
			bgColorClass: 'bg-tertiary'
		}
	];

	return (
		<section className={'flex flex-wrap pb-24'}>
			{items.map((item, index) => {
				return (
					<div
						key={index + 'benefits'}
						className={
							item.bgColorClass +
							' flex w-1/2 flex-col items-center justify-center gap-6 p-10 text-white md:w-1/4'
						}
					>
						<img src={item.icon} alt={item.title + ' icon'} />
						<div>
							<h4 className="text-center text-lg">
								{item.title}
							</h4>
							{item.text && (
								<p className="text-center text-base">
									{item.text}
								</p>
							)}
						</div>
					</div>
				);
			})}
		</section>
	);
};

export default Benefits;
