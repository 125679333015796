import * as React from 'react';

interface TextSectionProps {
	h2: string;
	p: string;
}

const TextSection: React.FC<TextSectionProps> = (props: TextSectionProps) => {
	return (
		<section>
			<div
				className={
					'container mx-auto flex flex-col items-center gap-10 px-4 py-14 text-center'
				}
			>
				<h2 className={'text-4xl text-[32px] font-normal text-quinary'}>
					{props.h2}
				</h2>
				<p className={'text-xl font-normal text-body'}>{props.p}</p>
			</div>
		</section>
	);
};

export default TextSection;
