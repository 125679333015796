import * as React from 'react';

import missionBg from '../../images/mission-bg.png';

const OurMission: React.FC = () => {
	return (
		<section
			style={{ backgroundImage: `url('${missionBg}')` }}
			className={
				'flex flex-col items-center justify-center bg-[#DCEAFF] bg-cover bg-center'
			}
		>
			<div className="container mx-auto flex justify-center px-4 py-16 md:justify-end">
				<div
					className={
						'justify-centerbg-white flex flex-col items-start gap-4 bg-white bg-opacity-80 p-4 pb-10 text-center text-quinary md:w-1/2'
					}
				>
					<h2 className={'text-4xl font-normal'}>OUR MISSION</h2>
					<h3 className="text-left text-3xl font-bold">
						Connecting People, Places and Opportunities
					</h3>
					<p className="text-left text-body">
						We strive to exceed our customers' expectations by
						providing safe, reliable, comfortable, and convenient
						transportation through the quality of our people, our
						services and innovation.
					</p>
				</div>
			</div>
		</section>
	);
};

export default OurMission;
