import * as React from 'react';

import MainButton from '../MainButton/MainButton';

const ReadyStartSection: React.FC = () => {
	return (
		<section
			className={
				'container mx-auto flex flex-col items-center gap-5 px-5 py-14 text-center text-body'
			}
		>
			<h3 className={'text-[32px] font-semibold md:text-[40px]'}>
				Ready to get started?
			</h3>
			<p className={'max-w-2xl text-lg font-normal'}>
				We are growing and want you to join our successful team today!
			</p>
			<a title={'Search'} href={'/jobs'}>
				<MainButton>Apply Now</MainButton>
			</a>
		</section>
	);
};

export default ReadyStartSection;
