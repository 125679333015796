import * as React from 'react';

import MainButton from '../MainButton/MainButton';

import featuredImg from '../../images/featured-careers-bg.png';

const FeaturedCareers: React.FC = () => {
	const opportunitiesItems = [
		{ text: 'Bus Drivers', href: '/jobs/?term=Driver' },
		{ text: 'Mechanics', href: '/jobs/?term=Mechanic' },
		{ text: 'Cleaners', href: '/jobs/?term=Cleaner' },
		{ text: 'Dispatchers', href: '/jobs/?term=Dispatcher' },
		{ text: 'Field Supervisors', href: '/jobs/?term=Supervisors' },
		{ text: 'Office Staff', href: '/jobs/?term=Office' },
		{ text: 'Management Positions', href: '/jobs/?term=Management' },
		{ text: 'View All Positions', href: '/jobs/' }
	];

	return (
		<section className={'bg-[#F5FAFE]'}>
			<div
				className={
					'container mx-auto flex flex-col items-center justify-center gap-10 pt-12'
				}
			>
				<h2 className={'text-4xl text-[32px] font-normal text-quinary'}>
					Featured Career Areas
				</h2>

				<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
					{opportunitiesItems.map((opp, index) => {
						return (
							<div
								className={
									'min-w-[250px] px-3 py-3 text-center'
								}
							>
								<a href={opp.href} key={opp.text + index}>
									<MainButton classList="w-full text-[20px] px-2">
										{opp.text}
									</MainButton>
								</a>
							</div>
						);
					})}
				</div>
			</div>
			<img
				src={featuredImg}
				alt=""
				className="max-h-[300px] w-[100vw] object-cover object-bottom"
			/>
		</section>
	);
};

export default FeaturedCareers;
