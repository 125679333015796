import * as React from 'react';

import safetyIcon from '../../images/safety-consious-icon.svg';
import ecoIcon from '../../images/eco-friendly-icon.svg';
import customerIcon from '../../images/customer-service-icon.svg';

const Values: React.FC = () => {
	const valueItems = [
		{
			icon: safetyIcon,
			title: 'Safety Conscious'
		},
		{
			icon: ecoIcon,
			title: 'Eco-Friendly'
		},
		{
			icon: customerIcon,
			title: 'Customer Service Oriented'
		}
	];

	return (
		<section className={'py-10'}>
			<div className="container mx-auto px-4">
				<div className={'flex flex-col gap-6 text-center'}>
					<h2 className={'text-4xl font-normal text-quinary'}>
						Our Values
					</h2>
				</div>
				<div
					className={
						'flex flex-wrap items-center justify-center gap-5 py-6'
					}
				>
					{valueItems.map((item, index) => {
						return (
							<div
								key={item.title + index + 'core'}
								className={
									'flex h-[200px] w-[300px] flex-col items-center justify-between gap-3 rounded-xl bg-white p-5'
								}
							>
								<div className={'flex h-1/2 items-center'}>
									<img
										src={item.icon}
										alt={item.title + 'icon'}
									/>
								</div>
								<div
									className={
										'flex h-1/2 flex-col items-center justify-center gap-3'
									}
								>
									<h3 className={'text-xl font-bold'}>
										{item.title}
									</h3>
								</div>
							</div>
						);
					})}
				</div>
				<div className="flex flex-col items-center justify-center gap-6 bg-tertiary py-6">
					<h3 className="text-center text-3xl font-bold text-white">
						Our Mission
					</h3>
					<p className="text-center text-xl text-white">
						To provide safe, convenient, affordable bus service
						across North America.
					</p>
				</div>
				<div className={'mx-auto flex self-center'}>
					<iframe
						className={'mx-auto aspect-video h-auto w-full'}
						src="https://www.youtube.com/embed/wWyk7FY0j50"
						title="YouTube Video Player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowFullScreen
					/>
				</div>
			</div>
		</section>
	);
};

export default Values;
