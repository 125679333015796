import * as React from 'react';

interface MainButtonProps {
	children: string;
	classList?: string;
}

const MainButton: React.FC<MainButtonProps> = (props: MainButtonProps) => {
	return (
		<button
			className={
				'rounded-[28px] border-2 border-transparent bg-secondary px-12 py-3 text-xl font-bold text-white transition-all hover:border-secondary hover:bg-white hover:text-secondary ' +
				props.classList
			}
		>
			{props.children}
		</button>
	);
};

export default MainButton;
